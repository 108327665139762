<template>
  <div class="bank-id-success">
    <Container>
      <Card padding="large">
        <b-row v-if="isLoading" align-h="center">
          <b-col cols="auto">
            <Loader class="margins__double" :text="$t('LOADING')" />
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col cols="12" sm="auto" class="text-center">
            <SuccessSVG class="icon h1 text-white" />
          </b-col>
          <b-col>
            <Margins>
              <h1 class="h3 text-normal">{{ $t('ORDER_COMPLETE') }}</h1>
              <template>
                <p class="text-small">
                  {{ $t('ORDER_COMPLETE_INFO') }}
                  <span>{{ $t('ORDER_COMPLETE_CONTACT') }}</span>
                  <span v-html="successText"></span>
                </p>
                <p class="text-small">{{ $t('ORDER_COMPLETE_INFO_2') }}</p>
              </template>
            </Margins>
          </b-col>
        </b-row>
      </Card>
    </Container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Card, Container, Loader, Margins } from '@/components';
import SuccessSVG from '@/assets/images/success.svg';
import { constants } from '@/mixins';
import { RESET_STORE } from '@/types';

export default {
  name: 'Success',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Loader,
    SuccessSVG,
  },
  data() {
    return {
      refreshTimer: null,
    };
  },
  async mounted() {
    this.refreshTimer = setTimeout(() => {
      this.RESET_STORE(true);
      this.$router.push({ name: this.ROUTES.HOME.name });
    }, 60000);
  },
  beforeDestroy() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
  },
  methods: {
    ...mapActions([RESET_STORE]),
  },
  computed: {
    ...mapState(['isLoading', 'loanReferenceNumber']),
    successText() {
      return this.$t('ORDER_COMPLETE_NUMBERS', [
        '<br />',
        `<strong>${this.loanReferenceNumber}</strong>`,
      ]);
    },
  },
};
</script>
